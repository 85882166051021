import React, { useState } from "react";
import Page from "components/Page";
import { withTranslation } from "react-i18next";
import constants from "./../../../../constants";
import { decodeId } from "utils";
import TimesheetForm from "../../Timesheet/TimesheetForm";
import Toast from "components/Toast/Alert";
import styles from "../../Timesheet/Timesheet.module.scss";
import { useOfficeDetail } from "repositories/office-repository";

function AddUpdateTimesheet({ history, match, location, t }) {
    
    const { data: officeDetail } = useOfficeDetail(
        decodeId(match.params.officeId)
    );

    const staffId = decodeId(match.params.staffId);

    const goBack = () => {
        history.push(constants.routes.accountOwner.timesheet.replace(':officeId',match.params.officeId))
    };

    const [errorMessage, setErrorMessage] = useState("");

    let officeId;
    try {
        officeId = decodeId(match.params.officeId);
        if (isNaN(officeId)) {
            history.push("/");
            return null;
        }
    } catch (e) {
        history.push("/");
        return null;
    }

    return (
        <Page onBack={goBack}>
            {officeDetail && officeDetail?.name && (
                <h2 className={"page-title " + styles["heading"]}>
                    {officeDetail.name}
                </h2>
            )}

            <div className={styles["sub-head"]}>{t("staff.addEditTimesheet")}</div>
            {errorMessage && (
                <Toast
                    errorToast
                    message={errorMessage}
                    handleClose={() => setErrorMessage("")}
                />
            )}
            <div className="account-setup-block pt-0">
                <div className={"form-wrapper " + styles["leave-form-wrapper"]}>
                    <div className="common-tabs scheduler-tabs edit-profile-tabs ">
                        <TimesheetForm
                            officeId={officeId}
                            onError={setErrorMessage}
                            t={t}
                            location={location}
                            staffId={staffId}
                            match={match}
                        />
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default withTranslation()(AddUpdateTimesheet);
