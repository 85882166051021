import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "../StaffListingTimesheet.module.scss";
import "../StaffListingTimesheet.scss";
import userDefaultImage from "./../../../../assets/images/staff-default-rounded.png";
import { useStaffList } from "repositories/timesheet-repository";
import Loader from "components/Loader";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce, uniqBy } from "lodash";
import toast from "react-hot-toast";
import constants from "./../../../../constants";
import { encodeId } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PAGE_SIZE = 10;
const animationVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

function StaffListingModal({
  t,
  isStaffModelOpen,
  setIsStaffModelOpen,
  officeId,
}) {
  const history = useHistory();

  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItems: null,
    totalPages: null,
  });

  const pageNumber = pagination?.currentPage;
  const { data: list, isLoading } = useStaffList(
    officeId,
    pageNumber,
    PAGE_SIZE,
    search
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setSearch(value);
        setPagination((prev) => ({ ...prev, currentPage: 1 }));
      }, 100),
    []
  );

  const onSearch = useCallback(
    (e) => {
      const value = e.target.value;
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (pageNumber === 1) {
      if (!isLoading && list?.data) {
        setMembersList(uniqBy([...list?.data], "id"));
        setPagination(list?.pagination);
      }
    } else if (!isLoading && list?.data) {
      setMembersList((prev) => uniqBy([...prev, ...list?.data], "id"));
      setPagination(list?.pagination);
    }
  }, [isLoading, list?.data, pagination]);

  const checkMember = (member) => {
    setSelectedMember(member?.userId);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = userDefaultImage;
    ev.target.onerror = null;
  };

  const goToNext = () => {
    if (pagination?.currentPage < pagination?.totalPages)
      setPagination((v) => ({ ...v, currentPage: v.currentPage + 1 }));
  };

  const handleSelect = () => {
    if (selectedMember) {
      const staffDetail = membersList?.find(
        (val) => val?.userId === selectedMember
      );

      history.push({
        pathname: constants.routes.accountOwner.updateTimesheetForStaff
          .replace(":officeId", encodeId(officeId))
          .replace(":staffId", encodeId(selectedMember)),
        state: { staffDetail },
      });
      setIsStaffModelOpen(false);
    } else {
      toast.error(t("vendorManagement.errors.selectOneItems"));
    }
  };

  return (
    <>
      <Modal
        isOpen={isStaffModelOpen}
        toggle={() => setIsStaffModelOpen(false)}
        className="modal-dialog-centered modal-lg modal-width-660"
        modalClassName="custom-modal"
      >
        {isLoading && <Loader />}
        <span className="close-btn" onClick={() => setIsStaffModelOpen(false)}>
          <img src={crossIcon} alt="close" />
        </span>
        <ModalBody className={styles["staff-list-modal-dialog"]}>
          <Text size="25px" marginBottom="10px" weight="500" color="#111b45">
            <span className="modal-title-25">
              {t("accountOwner.selectEmployees")}
            </span>
          </Text>
          <div className={"search-box w-100 " + styles["search-box"]}>
            <input
              type="text"
              placeholder={t("accountOwner.searchEmployee")}
              value={search}
              onChange={onSearch}
            />
            <span className="ico">
              <img
                src={require("assets/images/search-icon.svg").default}
                alt="icon"
              />
            </span>
          </div>

          <ul
            className={"modal-employee-list " + styles["employee-list"]}
            id="employee-list"
          >
            <motion.div
              variants={animationVariants}
              initial="hidden"
              animate="show"
            >
              <InfiniteScroll
                dataLength={membersList.length}
                hasMore={membersList.length < pagination?.totalItems}
                next={goToNext}
                scrollableTarget="employee-list"
              >
                {membersList.length > 0 &&
                  membersList.map((val, key) => (
                    <li key={key} className="timesheet-report-radio-btn">
                      <div className="ch-radio">
                        <label onClick={() => checkMember(val)}>
                          <input type="radio" name="selectMember" />
                          <span className="d-flex">
                            <img
                              src={val?.user?.profilePic || userDefaultImage}
                              width={40}
                              height={40}
                              onError={addDefaultSrc}
                              alt="profile"
                              style={{ borderRadius: "5px" }}
                            />
                            <div className="ml-2">
                              {val.firstName} {val.lastName}
                              <Text size="12px" weight="400" color="#87928D">
                                {val?.designation?.title}
                              </Text>
                            </div>
                          </span>
                        </label>
                      </div>
                    </li>
                  ))}
              </InfiniteScroll>
            </motion.div>

            {membersList.length === 0 && (
              <li>
                <div className="ch-checkbox">
                  <label>{t("noRecordFound")}</label>
                </div>
              </li>
            )}
          </ul>
          <button
            className="button button-round button-shadow mr-sm-3 mb-3 w-sm-100"
            title={t("accountOwner.addEmployees")}
            onClick={handleSelect}
          >
            {t("questionnaire.select")}
          </button>
          <button
            className="button button-round button-border btn-mobile-link button-dark mb-md-3"
            onClick={() => setIsStaffModelOpen(false)}
            title={t("cancel")}
          >
            {t("cancel")}
          </button>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTranslation()(StaffListingModal);
