import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import {
    timeSheetReportExport,
    useTimesheetReport,
} from "repositories/timesheet-repository";
import { useOfficeDetail } from "repositories/office-repository";
import Loader from "components/Loader";
import { handleError, getStorage, decodeId, setStorage } from "utils";
import constants from "../../../constants";
import styles from "../StaffListingTimesheet/StaffListingTimesheet.module.scss";
import "../StaffListingTimesheet/StaffListingTimesheet.scss";
import { useSelector } from "react-redux";
import TimesheetReportView from "./TimesheetReportView";

const TimesheetReport = ({ t, history, location }) => {
    const officeId = decodeId(useParams()?.officeId) || null;

    const [date, setDate] = useState({
        from: new Date(moment().startOf("month").format("YYYY-MM-DD")),
        to: new Date(),
    });

    const [staffListTimesheet, setStaffListTimesheet] = useState([]);
    const [holidays, setHolidays] = useState([]);

    const [showLoader, setShowLoader] = useState(false);

    const startDate = moment(date.from).format("YYYY-MM-DD");
    const endDate = moment(date.to).format("YYYY-MM-DD");

    const { data: officeDetail } = useOfficeDetail(officeId);
    const profile = useSelector((state) => state?.userProfile?.profile);

    const {
        data,
        isLoading,
        refetch,
    } = useTimesheetReport(Number(officeId), profile?.id, startDate, endDate);

    useEffect(() => {
        setStorage(constants.Timesheet.cache.timesheetReportCache, {
            dateFrom: date.from,
            dateTo: date.to,
        });
    }, [date]);

    useEffect(() => {
        if (!isLoading && data?.data) {
            setHolidays(data?.data?.holidays);
            setStaffListTimesheet(data?.data?.staff);
        }
    }, [isLoading, data]);

    const onBack = () => {
        history.goBack();
    };

    const changeDate = (obj) => {
        setDate((prev) => {
            let newDate = { ...prev };
            const currentDate = moment();

            // Handle "from" date change
            if (obj.hasOwnProperty('from')) {
                const selectedFromDate = moment(obj.from);
                const currentFromDate = moment(prev.from);
                const currentToDate = moment(prev.to);

                // Don't allow future dates
                if (selectedFromDate.isAfter(currentDate)) {
                    newDate.from = currentDate.toDate();
                } else {
                    newDate.from = obj.from;
                }

                const wasFirstDay = currentFromDate.date() === 1;
                const wasLastDay = currentToDate.isSame(moment(currentFromDate).endOf('month'), 'day');

                if (wasFirstDay && wasLastDay) {
                    if (selectedFromDate.date() === 1) {
                        const endOfMonth = moment(selectedFromDate).endOf('month');
                        newDate.to = endOfMonth.isAfter(currentDate) ? currentDate.toDate() : endOfMonth.toDate();
                    } else {
                        const currentGap = currentToDate.diff(currentFromDate, 'days');
                        let newToDate = moment(selectedFromDate).add(currentGap, 'days');
                        newDate.to = newToDate.isAfter(currentDate) ? currentDate.toDate() : newToDate.toDate();
                    }
                } else {
                    const currentGap = currentToDate.diff(currentFromDate, 'days');
                    let newToDate = moment(selectedFromDate).add(currentGap, 'days');
                    newDate.to = newToDate.isAfter(currentDate) ? currentDate.toDate() : newToDate.toDate();
                }
            }

            if (obj.hasOwnProperty('to')) {
                const selectedToDate = moment(obj.to);
                const fromDate = moment(newDate.from);

                if (selectedToDate.isBefore(fromDate) || selectedToDate.isAfter(currentDate)) {
                    return newDate;
                }

                if (selectedToDate.diff(fromDate, 'months', true) >= 1) {
                    if (fromDate.date() === 1) {
                        const endOfMonth = moment(fromDate).endOf('month');
                        newDate.to = endOfMonth.isAfter(currentDate) ? currentDate.toDate() : endOfMonth.toDate();
                    } else {
                        const oneMonth = moment(fromDate).add(1, 'month').subtract(1, 'day');
                        newDate.to = oneMonth.isAfter(currentDate) ? currentDate.toDate() : oneMonth.toDate();
                    }
                } else {
                    newDate.to = obj.to;
                }
            }

            return newDate;
        });
    };


    const getHolidays = () => {
        const getholidays = holidays.map(
            (val) => `${val.title}(${moment(val.date).format("ll")})`
        );

        return getholidays?.join(" | ");
    };

    const downloadTimesheetExportedData = async () => {
        setShowLoader(true);

        try {
            let res = await timeSheetReportExport(startDate, endDate, data?.data);
            if (res?.data) {
                window.open(res?.data?.file_Url, "_self");
            }
        } catch (err) {
            handleError(err);
        } finally {
            setShowLoader(false);
        }
    };

    const handleNext = () => {
        setDate((prev) => {
            const fromDate = moment(prev.from);
            const toDate = moment(prev.to);
            const currentDate = moment();

            const isFirstDay = fromDate.date() === 1;
            const isLastDay = toDate.isSame(moment(fromDate).endOf('month'), 'day');

            if (isFirstDay && isLastDay) {
                const newFromDate = moment(fromDate).add(1, 'month').startOf('month');

                if (newFromDate.isAfter(currentDate)) {
                    return prev;
                }

                const newToDate = moment(newFromDate).endOf('month');
                return {
                    from: newFromDate.toDate(),
                    to: newToDate.isAfter(currentDate) ? currentDate.toDate() : newToDate.toDate()
                };
            } else {
                const daysDiff = toDate.diff(fromDate, 'days');
                const newFromDate = moment(toDate).add(1, 'days');

                if (newFromDate.isAfter(currentDate)) {
                    return prev;
                }

                const newToDate = moment(newFromDate).add(daysDiff, 'days');
                return {
                    from: newFromDate.toDate(),
                    to: newToDate.isAfter(currentDate) ? currentDate.toDate() : newToDate.toDate()
                };
            }
        });
    };


    const handlePrevious = () => {
        setDate((prev) => {
            const fromDate = moment(prev.from);
            const toDate = moment(prev.to);
            const currentDate = moment();

            const isFirstDay = fromDate.date() === 1;
            const isLastDay = toDate.isSame(moment(fromDate).endOf('month'), 'day');

            if (isFirstDay && isLastDay) {
                const newFromDate = moment(fromDate).subtract(1, 'month').startOf('month');
                const newToDate = moment(newFromDate).endOf('month');

                return {
                    from: newFromDate.toDate(),
                    to: newToDate.isAfter(currentDate) ? currentDate.toDate() : newToDate.toDate()
                };
            } else {
                const daysDiff = toDate.diff(fromDate, 'days');
                const newFromDate = moment(fromDate).subtract(daysDiff + 1, 'days');
                const newToDate = moment(fromDate).subtract(1, 'days');

                return {
                    from: newFromDate.toDate(),
                    to: newToDate.isAfter(currentDate) ? currentDate.toDate() : newToDate.toDate()
                };
            }
        });
    };
    const goToCurrentDate = () => {
        setDate((prev) => {
            const daysDiff = moment(prev.to).diff(moment(prev.from), "days");
            const today = moment().startOf("day");

            const newToDate = moment
                .min(today, moment(today).add(daysDiff, "days"))
                .toDate();

            const newFromDate = moment(newToDate).subtract(daysDiff, "days").toDate();

            return {
                from: newFromDate,
                to: newToDate,
            };
        });
    };

    const renderHeader = () => (
        <>
            {officeDetail && officeDetail.name && (
                <h2 className="page-title mt-3 date-page-title">{officeDetail.name}</h2>
            )}
            <h5 className={styles["sub-head"]}>{t("staffTimesheet.timesheetReport")}</h5>
        </>
    );

    const renderFilters = () => (
        <div
            className={
                "d-flex justify-content-between align-items-center " +
                styles["left-main"]
            }
        >
            <div className={"d-flex align-items-center " + styles["left-container"]}>
                <div className={styles["calendar-box"]}>
                    <div className="c-field m-0">
                        <label>{t("from")}</label>
                        <div className="d-flex inputdate">
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                className="c-form-control"
                                selected={date.from}
                                onSelect={(value) => changeDate({ from: value })}
                                maxDate={date.to}
                            />
                        </div>
                    </div>
                    <div className="c-field m-0">
                        <label>{t("to")}</label>
                        <div className="d-flex inputdate">
                            <DatePicker
                                popperPlacement="bottom-end"
                                dateFormat="dd-MM-yyyy"
                                className="c-form-control"
                                selected={date.to}
                                onSelect={(value) => changeDate({ to: value })}
                                minDate={date.from}
                                maxDate={moment.min(moment(date.from).add(1, "month"), moment()).toDate()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderExportAndHolidayList = () => (
        <>
            {holidays.length > 0 && (
                <p className={styles["holidays-description"]}>
                    <span>{t("staffTimesheet.holidaysDescription")}</span>
                    <span className={styles["holidays"]}>{getHolidays()}</span>
                </p>
            )}

            <div className={styles.btn_wrapper}>
                <div className={styles.timesheet_report_btn}>
                    <button
                        className={
                            "button button-round button-border w-sm-100 button-dark "
                        }
                        onClick={downloadTimesheetExportedData}
                        title={t("staffTimesheet.exportTimesheetData")}
                    >
                        {t("staffTimesheet.exportTimesheetData")}
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <>
            <Page
                className={"staff-listing-timesheet " + styles["timesheet-page"]}
                onBack={onBack}
            >
                {(isLoading || showLoader) && <Loader />}
                {renderHeader()}
                {renderFilters()}
                {renderExportAndHolidayList()}
                <TimesheetReportView
                    t={t}
                    officeId={officeId}
                    setShowLoader={setShowLoader}
                    refetch={refetch}
                    startDate={startDate}
                    endDate={endDate}
                    holidays={holidays}
                    staffListTimesheet={staffListTimesheet}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    goToCurrentDate={goToCurrentDate}
                />
            </Page>
        </>
    );
};

export default withTranslation()(TimesheetReport);
