import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import crossIcon from "../../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "./TimesheetReportHeader.module.scss";
import moment from "moment";

const TimesheetBreakModal = ({ t, isModalOpen, closeModal, clockInOutBreakTime }) => {

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={closeModal}
      className="modal-dialog-centered"
      modalClassName="custom-modal"
    >
      <span className="close-btn" onClick={closeModal}>
        <img src={crossIcon} alt="close" />
      </span>
      <ModalBody>
        {clockInOutBreakTime?.updatedBy && <div className="edit-profile-warning-container container mb-0 mt-4 p-0">
          <div className="edit-profile-warning-bg ">
            <Text size="10px" weight="500" color="#2F3245" lineHeight="16px">
              {t('staffTimesheet.note')}: {t('staffTimesheet.clockInClockMsg')}
            </Text>
          </div>
        </div>}
        <div className={styles.break_timesheet_table_header}>
          <Text size="14px" weight="500" color="#2F3245" lineHeight="16px">
            {t('staffTimesheet.break')}
          </Text>
          <div className={styles.break_table_container}>
            <div className={styles.break_table_header}>{t('staffTimesheet.startTime')}</div>
            <div className={styles.break_table_header}>{t('staffTimesheet.endTime')}</div>

            {clockInOutBreakTime?.clockInOutBreakTime.map((b) => (
              <Fragment key={b?.id}>
                <div className={styles.break_table_cell}>{moment(b?.breakStart).format("hh:mm A")}</div>
                <div className={styles.break_table_cell}>{moment(b?.breakEnd).format("hh:mm A")}</div>
              </Fragment>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(TimesheetBreakModal);
